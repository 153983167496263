import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import LadakhImg from "../../assets/Images/HomeImage.png";
import { Link } from "react-router-dom";
import Footer from "../HomePage/Footer";
import Nav from "../HomePage/Nav";
import Biking from "../../assets/Images/biking.svg";
import Culture from "../../assets/Images/cultural.svg";
import Adventure from "../../assets/Images/adventure.svg";
import Wildlife from "../../assets/Images/wildlife.svg";
import TrekkingImage from "../../assets/Images/trekking.svg";
import RaftingImage from "../../assets/Images/rafting.svg";

const AllPackages = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    Trekking: false,
    Rafting: false,
    Biking: false,
    Adventure: false,
    Wildlife: false,
    Cultural: false,
  });
  const [initialRender, setInitialRender] = useState(true);
  const [showAllPackages, setShowAllPackages] = useState(true);

  const iconMapping = {
    Trekking: TrekkingImage,
    Rafting: RaftingImage,
    Biking: Biking,
    Adventure: Adventure,
    Wildlife: Wildlife,
    Cultural: Culture,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}AllPackages`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const Isid = data.data;
        // console.log("hehe", Isid);
        setPackages(data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
    setFilterOptions({
      Trekking: false,
      Rafting: false,
      Biking: false,
      Adventure: false,
      Wildlife: false,
      Cultural: false,
    });
    setInitialRender(false);
  }, []);
  const handleBook = (dataItem) => {
    sessionStorage.setItem("slugg", dataItem.slug);
    sessionStorage.setItem("selectedId", dataItem.id);
    if (dataItem.category.id === 1) {
      window.location.href = "/BookOnlineCarHotel"; // Navigate to "/BookOnlineCarHotel"
    } else if (dataItem.category.id === 2) {
      window.location.href = "/BookOnlineCarHotel";
    } else {
      window.location.href = "/BookOnline"; // Navigate to "/BookOnline"
    }
  };
  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  
  //   if (name === "allCheckbox") {
  //     setShowAllPackages(true);
  //     setFilterOptions({
  //       Trekking: false,
  //       Rafting: false,
  //       Biking: false,
  //       Adventure: false,
  //       Wildlife: false,
  //       Cultural: false,
  //     });
  //   } else {
  //     // Update the specific filter and uncheck "All" if any individual filter is selected
  //     setFilterOptions((prevOptions) => {
  //       const updatedOptions = {
  //         ...prevOptions,
  //         [name]: checked,
  //       };
  
  //       // Check if all specific filters are now unchecked
  //       const isAllUnchecked = Object.values(updatedOptions).every((val) => val === false);
  //       setShowAllPackages(isAllUnchecked); // Set "All" based on whether all specific filters are unchecked
  
  //       return updatedOptions;
  //     });
  //   }
  // };
  
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
  
    if (name === "allCheckbox") {
      // If "All" checkbox is selected, deselect all specific filters
      setShowAllPackages(checked);
      setFilterOptions({
        Trekking: false,
        Rafting: false,
        Biking: false,
        Adventure: false,
        Wildlife: false,
        Cultural: false,
      });
    } else {
      // Update specific filters and check if all are selected
      setFilterOptions((prevOptions) => {
        const updatedOptions = { ...prevOptions, [name]: checked };
        const allSelected = Object.values(updatedOptions).every((val) => val);
  
        // Automatically select "All" checkbox if all individual filters are checked
        setShowAllPackages(allSelected);
        return updatedOptions;
      });
    }
  };
  

  const filteredPackages = packages.filter((item) => {
    if (!item || !item.id) return false;
    const filterSearch = item.FilterSearch || "";
    if (
      showAllPackages ||
      (filterOptions.Trekking && filterSearch.includes("Trekking")) ||
      (filterOptions.Rafting && filterSearch.includes("Rafting")) ||
      (filterOptions.Biking && filterSearch.includes("Biking")) ||
      (filterOptions.Adventure && filterSearch.includes("Adventure")) ||
      (filterOptions.Wildlife && filterSearch.includes("Wildlife")) ||
      (filterOptions.Cultural && filterSearch.includes("Cultural"))
    ) {
      return true;
    }
    return false;
  });
  
  const handleLinkClick = (slug) => {
    console.log("sluggs",slug);
    sessionStorage.setItem("slugg",slug);
    // window.location.href = `/PackageDestination/${slug}`;
    window.location.href = `/${slug}`;
  };

  return (
    <>
      <div className="container-fluid">
        <Nav />

        <div className="col-lg-12 px-0 position-relative">
          <div
            className="image-container"
            style={{
              width: "100%",
              height: "65vh",
              overflow: "hidden",
              border: "1px solid #ddd",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              position: "relative",
            }}
          >
            <img
              src={LadakhImg}
              alt="ladakhDestinations"
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div
              className="overlay-text"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                textAlign: "center",
                opacity: 0.9,
              }}
            >
              <h1 style={{ fontSize: "3rem" }}>
                All Packages That We Provide...
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}

        <div className="row justify-content-center">
          {/* // Refine Search */}

          <div className="p-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
           
            <div
              className="rounded shadow p-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
              }}
            >
              <div className="text-center">
                <h4>
                  <b>Refine Search</b>
                </h4>
                <hr style={{ borderTop: "1px solid #000000" }} />
              </div>

              <div style={{ color: "#023E8A" }}>
                <label htmlFor="allCheckbox" className="mb-4">
                  <input
                    type="checkbox"
                    id="allCheckbox"
                    name="allCheckbox"
                    checked={showAllPackages}
                    onChange={handleCheckboxChange}
                  />{" "}
                  All
                </label>

                <div className="d-flex flex-wrap justify-content-between mb-3">
                  <div className="d-flex align-items-center me-4 mb-2">
                    <label
                      htmlFor="trekking"
                      onClick={() =>
                        handleCheckboxChange({
                          target: {
                            name: "Trekking",
                            checked: !filterOptions.Trekking,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        id="Trekking"
                        name="Trekking"
                        checked={filterOptions.Trekking}
                        onChange={() => {}}
                      />{" "}
                      <img
                        src={TrekkingImage}
                        alt="Trekking"
                        width="25"
                        height="25"
                        style={{ color: "#74C0FC" }}
                      />
                    </label>
                    <p className="mb-0">Trekking</p>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <label
                      htmlFor="raftingCheckbox"
                      onClick={() =>
                        handleCheckboxChange({
                          target: {
                            name: "Rafting",
                            checked: !filterOptions.Rafting,
                          },
                        })
                      } 
                    >
                      <input
                        type="checkbox"
                        id="Rafting"
                        name="Rafting"
                        checked={filterOptions.Rafting}
                        onChange={() => {}}
                      />{" "}
                      <img
                        src={RaftingImage}
                        alt="Rafting"
                        width="25"
                        height="25"
                        style={{ color: "#74C0FC" }}
                      />
                    </label>
                    <p className="mb-0">Rafting</p>
                  </div>

                </div>

                <hr style={{ borderTop: "1px solid #000000" }} />

                <div className="d-flex flex-wrap justify-content-between mb-3">
                  <div className="d-flex align-items-center me-4 mb-2">
                    <label
                      htmlFor="Wildlife"
                      onClick={() =>
                        handleCheckboxChange({
                          target: {
                            name: "Wildlife",
                            checked: !filterOptions.Wildlife,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        id="Wildlife"
                        name="Wildlife"
                        checked={filterOptions.Wildlife}
                        onChange={() => {}}
                      />{" "}
                      <img
                        src={Wildlife}
                        alt="Wildlife"
                        width="25"
                        height="25"
                        style={{ color: "#74C0FC" }}
                      />
                    </label>
                    <p className="mb-0">Wildlife</p>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <label
                      htmlFor="Adventure"
                      onClick={() =>
                        handleCheckboxChange({
                          target: {
                            name: "Adventure",
                            checked: !filterOptions.Adventure,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        id="Adventure"
                        name="Adventure"
                        checked={filterOptions.Adventure}
                        onChange={() => {}} 
                      />{" "}
                      <img
                        src={Adventure}
                        alt="Adventure"
                        width="25"
                        height="25"
                        style={{ color: "#74C0FC" }}
                      />
                    </label>
                    <p className="mb-0">Adventure</p>
                  </div>
                </div>

                <hr style={{ borderTop: "1px solid #000000" }} />

                <div className="d-flex flex-wrap justify-content-between mb-3">
                  <div className="d-flex align-items-center me-4 mb-2">
                    <label
                      htmlFor="Biking"
                      onClick={() =>
                        handleCheckboxChange({
                          target: {
                            name: "Biking",
                            checked: !filterOptions.Biking,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        id="Biking"
                        name="Biking"
                        checked={filterOptions.Biking}
                        onChange={() => {}}
                      />{" "}
                      <img
                        src={Biking}
                        alt="Biking"
                        width="25"
                        height="25"
                        style={{ color: "#74C0FC" }}
                      />
                    </label>
                    <p className="mb-0">Biking</p>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <label
                      htmlFor="Cultural"
                      onClick={() =>
                        handleCheckboxChange({
                          target: {
                            name: "Cultural",
                            checked: !filterOptions.Cultural,
                          },
                        })
                      }
                    >
                      <input
                        type="checkbox"
                        id="Cultural"
                        name="Cultural"
                        checked={filterOptions.Cultural}
                        onChange={() => {}}
                      />{" "}
                      <img
                        src={Culture}
                        alt="Culture"
                        width="25"
                        height="25"
                        style={{ color: "#74C0FC" }}
                      />
                    </label>
                    <p className="mb-0">Cultural</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-8 mb-5">
            {packages.length > 0 ? (
              filteredPackages.length > 0 ? (
                filteredPackages.map((dataItem) => (
                  <div
                    key={dataItem.id}
                    className="card border-0 mb-5"
                    style={{
                      width: "100%",
                      boxShadow: "0 0 30px 0 rgb(6 30 98 / 8%)",
                    }}
                  >
                    <div className="row no-gutters ms-0">
                      <div className="col-md-5 d-flex justify-content-center align-items-center">
                        <img
                          className="w-100 object-fit-cover zoom-img"
                          src={`${dataItem.image[0]}`}
                          alt={dataItem.name}
                          style={{ borderRadius: "10px", height: "245px" }}
                        />
                      </div>
                      <div className="col-md-7 d-flex justify-content-center align-items-center">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              {dataItem.category.id !== 1 && (
                                <h6
                                  className="card-title"
                                  style={{ color: "#023E8A" }}
                                >
                                  <FontAwesomeIcon icon={faCalendar} />{" "}
                                  {dataItem.days} Days
                                </h6>
                              )}
                            </div>

                            <div className="d-flex">
                              {dataItem.category.id !== 1 &&
                              dataItem.FilterSearch &&
                              dataItem.FilterSearch.length > 0
                                ? dataItem.FilterSearch.map(
                                    (searchItem, index) => (
                                      <img
                                        key={index}
                                        className="w-100 object-fit-cover lazy"
                                        src={iconMapping[searchItem]}
                                        alt={searchItem}
                                        style={{
                                          marginRight: "5px",
                                          borderRadius: "10px",
                                          maxHeight: "25px",
                                        }}
                                      />
                                    )
                                  )
                                : dataItem.category.id !== 1 && (
                                    <p>No images found</p>
                                  )}
                            </div>
                          </div>

                          <hr className="my-2" />
                          <h5 className="card-text">
                            <b>{dataItem.name}</b>
                          </h5>
                          <p
                            className="card-text"
                            style={{ color: "#666666", fontSize: "14px" }}
                          >
                            <FontAwesomeIcon
                              icon={faLocationDot}
                              className="me-2"
                            />
                            {dataItem.location}
                          </p>

                          {/* <p className="text-end">
                            <b>from Rs. {dataItem.price}/adult</b>
                          </p> */}
                          <p className="text-end">
                            <b>
                              {dataItem.category.id === 1
                                ? `from Rs. ${dataItem.price[0]}/night`
                                : `from Rs. ${dataItem.price}/adult`}
                            </b>
                          </p>

                          <div className="d-flex justify-content-end gap-4">
                            <Link
                              // to={`/PackageDestination/${dataItem.id}`}
                              className="btn btn-outline-primary"
                              style={{ borderRadius: "8px" }}
                              onClick={() => handleLinkClick(dataItem.slug)}
                            >
                              View Details
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-right ms-2"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                                />
                              </svg>
                            </Link>
                            <Link
                              onClick={() => handleBook(dataItem)}
                              className="btn btn-primary"
                            >
                              Book Online
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <h3 style={{ color: "grey" }}>
                    No packages available for the selected filter..
                  </h3>
                </div>
              )
            ) : (
              <div className="text-center">
                <h3 style={{ color: "grey" }}>No packages available..</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllPackages;
